<template>
  <div class="main">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="pageTitle">
          <template v-slot:toolbar>
            <!-- <div class="row">
              <div class="ml-3">
                <b-button
                  @click="create"
                  variant="primary"
                  size="sm"
                  class="font-weight-bold"
                >
                  <i class="flaticon2-add-1"></i>
                  Thêm mới
                </b-button>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-5">
                <b-dropdown
                  size="sm"
                  id="dropdown-1"
                  right
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="fas fa-cog"
                    ></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item
                    @click="exportExcel"
                    v-if="checkPermissions(['SHIPMENT_EXPORT'])"
                  >
                    <span>
                      <i
                        style="font-size: 1rem"
                        class="far fa-file-excel"
                      ></i>
                      &nbsp; Xuất Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col>
                <b-form-input
                  v-model="searchId"
                  type="text"
                  placeholder="ID phiếu"
                  size="sm"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  v-model="searchName"
                  type="text"
                  placeholder="Mã phiếu"
                  size="sm"
                ></b-form-input>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="dpForm.startDate"
                  ></date-picker>
                  <span class="mr-1 ml-1"></span>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="dpForm.endDate"
                  ></date-picker>
                </div>
              </b-col>
              <b-col>
                <b-form-select
                  size="sm"
                  v-model="selectedStatus"
                  :options="statusOptions"
                ></b-form-select>
              </b-col>
              <b-col>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  class="font-weight-bold w-100"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                >Lọc</b-button>
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="shipments"
              :fields="tableFields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:cell(stockSlips)="row">
                <template v-for="(entity, index) in row.item.stockSlips">
                  <p
                    :key="index"
                    class="mb-0 fw-bolder text-gray-600 text-hover-primary cursor-pointer"
                    @click="editItem(entity.id)"
                  >{{ entity.code }}</p>
                </template>
              </template>
              <template v-slot:cell(statusName)="row">
                <span :class="mapStyleByStatus(row.item.status)">
                  {{ row.item.statusName }}
                </span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown
                    size="sm"
                    id="dropdown-left"
                    no-caret
                    right
                  >
                    <template slot="button-content">
                      <i class="flaticon2-settings fs-6 pr-0"></i>
                    </template>
                    <b-dropdown-item
                      v-if="checkPermissions(['SHIPMENT_UPDATE'])"
                      @click="editShipment(row.item)"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-pen icon-color"
                        ></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermissions(['SHIPMENT_DELETE'])"
                      @click="showDeleteAlert(modalTitle, modalDesc, row.item, confirmDelete)"
                    >
                      <span class="text-dark fs-6">
                        <i class="flaticon2-rubbish-bin-delete-button fs-6 text-danger"></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->
            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <BPaginationNavCustom
                  :number-of-pages="totalPages"
                  :current-page="page"
                  :total-page="totalPages"
                  @page-change="onFilter"
                >
                </BPaginationNavCustom>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  table td {
    vertical-align: middle;
    .status {
      border-radius: 0.425rem;
    }
  }
  .card-toolbar {
    padding-right: 0;
  }
  .td-note {
    width: 20%;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import timeUtils from '@/utils/date';
import moment from 'moment';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import ApiService from '@/core/services/api.service';
import { convertPrice, makeToastSuccess, makeToastFaile } from '@/utils/common';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import { BASE_URL } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import axios from 'axios';
import { checkPermissions } from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    BPaginationNavCustom,
  },
  data() {
    return {
      searchId: null,
      modalTitle: 'Xóa chuyến hàng!',
      modalDesc: 'Bạn có chắc muốn chuyến hàng này không ?',
      searchName: '',
      selectedStatus: null,
      statusOptions: [
        { value: null, text: 'Tất cả trạng thái' },
        { value: 1, text: 'Đang vận chuyển' },
        { value: 2, text: 'Đang hoãn' },
        { value: 3, text: 'Đã huỷ' },
        { value: 4, text: 'Đã hoàn tất' },
      ],
      onLoading: false,
      dpConfigs: null,
      pageTitle: 'Danh sách vận chuyển',
      currentPage: 1,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      shipments: [],
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'stockSlips',
          label: 'Mã phiếu',
        },
        {
          key: 'entityTypeName',
          label: 'Loại phiếu',
          tdClass: 'text-center',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          tdClass: 'text-center',
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền',
          tdClass: 'text-right',
          formatter: (val) => convertPrice(val),
        },
        {
          key: 'note',
          label: 'Ghi chú',
          tdClass: 'td-note',
        },
        {
          key: 'updatedByName',
          label: 'Người cập nhật',
          tdClass: 'text-center',
        },
        {
          key: 'updatedAt',
          label: 'Ngày cập nhật',
          tdClass: 'text-center',
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
    };
  },
  methods: {
    checkPermissions,
    getParamFilter() {
      if (!this.page) {
        this.page = 1;
      }
      return {
        page: this.page,
        limit: 10,
      };
    },
    exportExcel() {
      const params = this.getParamFilter();
      const url = `${BASE_URL}v2/shipments/excel`;
      axios
        .get(url, {
          params: {
            ...params,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    create() {
      this.$router.push({
        name: 'upsert-shipment',
      });
    },
    mapStyleByStatus(status) {
      const mapStyles = {
        1: 'badge badge-pill badge-primary status',
        2: 'badge badge-pill badge-warning status',
        3: 'badge badge-pill badge-danger status',
        4: 'badge badge-pill badge-success status',
      };
      return mapStyles[status];
    },
    editItem: function (entityId) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: entityId },
      });
    },
    confirmDelete(deleteItem) {
      const removingIndex = this.shipments.findIndex(
        (item) => item.id === deleteItem.id,
      );
      if (removingIndex === -1) return;
      ApiService.delete(`/v2/shipments/${this.shipments[removingIndex].id}`)
        .then(({ data }) => {
          this.fetchShipments();
          makeToastSuccess(data.message);
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert,
    editShipment(item) {
      this.$router.push({
        name: 'upsert-shipment',
        query: {
          shipmentId: item.id,
        },
      });
    },
    fetchShipments() {
      const paramQuery = {
        params: {
          shipmentId: this.searchId,
          entityCode: this.searchName || null,
          fromDate: this.dpForm.startDate
            ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
            : '',
          toDate: this.dpForm.endDate
            ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
            : '',
          status: this.selectedStatus,
          page: this.page || 1,
        },
      };
      ApiService.query('/v2/shipments', paramQuery)
        .then((response) => {
          const responseData = response.data.data;
          this.totalPages = responseData.totalPage;
          this.totalItems = responseData.totalRow;
          this.shipments = responseData.dataset;
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    onFilter() {
      this.page = this.$route.query.page;
      this.fetchShipments();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Vận chuyển', route: 'shipment' },
      { title: this.pageTitle },
    ]);
  },
  created() {
    this.page = this.$route.query.page || 1;
    this.dpConfigs = timeUtils.DP_CONFIG;
    this.fetchShipments();
  },
};
</script>
